



















































































































import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Login extends Vue {
  @Prop({}) data!: any;
  private tab = 'login';
  private showPassword = false;
  private user = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  };

  private created() {
    if (this.data && this.data.page) this.tab = this.data.page;
  }
}
